<template>
  <div class="yedone">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel height="80px" direction="vertical" arrow="never" indicator-position="none" :interval="5000">
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div class="active" @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="yedonemain"></div>
        <!-- 公司简介 -->
        <div class="yedoneinfo">
          <div class="subject">
            <div class="left">
              <div class="leftTitle">公司简介</div>
              <div class="text1">
                重庆跃动网络技术有限公司成立于2008年，是一家专业从事智能安全类产品研发、技术咨询、安全服务的互联网科技公司。公司业务主要覆盖智能安全、系统服务、广告传媒和网络营销等多个方向。
              </div>
              <div class="text2">
                公司专注于服务电竞馆，电竞酒店，民宿等各类场所，主要产品包括：8圈智能场所管理平台、跃动网络代理商平台、掌上8圈APP、去上网APP、8圈机器人、8圈鹰眼以及面向第三方开发人员的应用市场开放平台。目前已拥有重庆、四川、海南、陕西、河南、宁夏、内蒙古、甘肃、云南等地的场所资源，共计1.3万余家，100多万台场所终端。
              </div>
              <div class="text2">
                公司始终致力于自主研发关键产品，坚持不懈的推进自主创新，多项技术产品填补了国内空白，不仅有力的推动了企业的持续增长，而且带动了全行业的技术进步。公司先后被评为“重庆市级重点软件创新型企业”、“重庆市创新型中小企业”等称号。未来我们将不断提升自主研发技术能力，为广大用户提供更广泛的前沿技术产品和服务，实现用户和企业的共赢。
              </div>
            </div>
            <div class="right">
              <img class="back" src="../assets/img/yedone.png" />
              <img class="image" src="../assets/img/rec.png" />
            </div>
          </div>
        </div>
        <!-- 企页文化 -->
        <div class="culture">
          <div class="subject">
            <div class="left">
              <div class="leftdiv" v-for="(item, i) of table" :key="i">
                <div>{{ item.title }}</div>
                <div>{{ item.info }}</div>
              </div>
            </div>
            <div class="right">
              <div class="rightdiv">企业文化</div>
              <div class="rightdiv2"></div>
            </div>
          </div>
        </div>
        <!-- 发展历程 -->
        <div class="develop">
          <div class="developinfo">
            <div class="infotitle">发展历程</div>
            <div class="developdev"></div>
          </div>
        </div>
        <!-- 企业管理 -->
        <div class="business">
          <div class="businessinfo">
            <img src="../assets/img/yedoneback4.png" />
            <div class="text">
              <div class="title">企业管理</div>
              <div>
                公司历来重管理、讲效率，凭借先进的企业管理体系，努力打造一个高效率、高质量的团队。公司一贯秉承以人为本的原则，采取人性化的管理模式，通过为员工营造一个舒适、自然的工作环境，使员工在轻松和谐的工作氛围中与公司共创价值。公司深知“企业要发展，人才是关键”，公司一贯尊重员工并鼓励员工创新，积极参与企业管理，与公司构成命运共同体，并不断加大科研和技术创新的投入，持续全面的提升团队综合能力水平。
              </div>
            </div>
          </div>
        </div>
        <!-- 公司展望 -->
        <div class="outlook">
          <div class="outlooktitle">公司展望</div>
          <div class="outlookinfo">
            展望未来，重庆跃动网络技术有限公司将立足高端智能系统的开发，使系统更加智能和完善，不断提升自主研发技术能力，贴近客户需求，助力中国智能，持续为全国网吧、校园及社区，利用自身独特优势为用户提供多种增值业务服务及娱乐应用。
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Yedone",
  components: { footerComponents },
  data() {
    return {
      table: [
        {
          title: "企业作风",
          info: "所有的伟大，都是拼出来的",
        },
        {
          title: "企业精神",
          info: "专业、专注、专心",
        },
        {
          title: "企业宗旨",
          info: "专注能够创造奇迹",
        },
        {
          title: "企业愿景",
          info: "打造最具竞争力的互联网企业",
        },
        {
          title: "企业使命",
          info: "提供有竞争力的产品与服务，持续为客户创造最大价值",
        },
        {
          title: "企业价值",
          info: "勤劳、朴实、学习、创新",
        },
      ],
    };
  },
  mounted() {
  	if(/Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)) {
  		//移动设备打开的网页，缩放页面尺寸
		/*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
		*/
	   debugger;
	   var rate = 0.1;
  		var meta = document.getElementsByTagName('meta');
  		meta['viewport'].setAttribute('content', 'width=device-width,initial-scale='+rate);
  	}
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong() {
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi(){
      this.$router.push("/contactUs");
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  margin: 0 auto;
  padding: 0;
  .yedonemain {
    height: 720px;
    background-image: url(../assets/img/yedoneback.jpg);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position:center;
  }
  .yedoneinfo {
    height: 580px;
    background-image: url(../assets/img/yedoneinfoback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    padding-top: 80px;
    box-sizing: border-box;
    .subject {
      width: 1200px;
      height: 406px;
      margin: 0 auto;
      display: flex;
      .left {
        width: 638px;
        .leftTitle {
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 36px;
          color: #161e2a;
        }
        .text1 {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 28px;
          color: #626778;
          margin-top: 20px;
        }
        .text2 {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 28px;
          color: #626778;
          margin-top: 18px;
        }
      }
      .right {
        margin: 50px 0 0 42px;
        width: 520px;
        height: 356px;
        position: relative;
        z-index: 9;
        .back{
          width: 520px;
          height: 356px;
        }
        .image {
          position: absolute;
          right: -20px;
          top: -20px;
          z-index: -1;
        }
      }
    }
  }
  .culture {
    height: 500px;
    box-sizing: border-box;
    background-image: url(../assets/img/yedoneback2.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    padding-top: 68px;
    .subject {
      width: 1200px;
      height: 352px;
      display: flex;
      margin: 0 auto;
      .left {
        margin-top: 12px;
        width: 880px;

        .leftdiv {
          width: 280px;
          height: 160px;
          background: rgba(0, 64, 151, 0.48);
          padding-top: 40px;
          box-sizing: border-box;
          float: left;
          margin-left: 20px;

          &:nth-child(1),
          &:nth-child(4) {
            margin-left: 0;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            margin-top: 20px;
          }

          > div:nth-child(1) {
            font-size: 20px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            line-height: 18px;
            color: #ffffff;
            margin-left: 30px;
          }
          > div:nth-child(2) {
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            line-height: 24px;
            color: #ffffff;
            opacity: 0.68;
            margin: 11px 0 0 30px;
          }
        }
      }
      .right {
        width: 222px;
        margin-left: 98px;
        .rightdiv {
          width: 222px;
          height: 108px;
          box-sizing: border-box;
          background-image: url(../assets/img/wenhua1.png);
          background-size: cover;
          /* 将背景图片等比缩放填满整个容器 */
          background-repeat: no-repeat;
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 108px;
          color: #ffffff;
          text-align: right;
        }
        .rightdiv2 {
          width: 126.67px;
          height: 38px;
          background-image: url(../assets/img/wenhua2.png);
          background-size: cover;
          /* 将背景图片等比缩放填满整个容器 */
          background-repeat: no-repeat;
          margin: 8px 0 0 95px;
        }
      }
    }
  }
  .develop {
    height: 740px;
    box-sizing: border-box;
    padding-top: 80px;
    .developinfo {
      width: 1200px;
      height: 580px;
      margin: 0 auto;
      .infotitle {
        width: 310px;
        height: 54px;
        margin: 0 auto;
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        line-height: 54px;
        color: #161e2a;
        text-align: center;
      }
      .developdev {
        width: 1200px;
        height: 466px;
        margin-top: 60px;
        box-sizing: border-box;
        background-image: url(../assets/img/yedoneback3.png);
        background-size: cover;
        /* 将背景图片等比缩放填满整个容器 */
        background-repeat: no-repeat;
      }
    }
  }
  .business {
    height: 640px;
    background: #f4f5f9;
    box-sizing: border-box;
    padding-top: 90px;
    .businessinfo {
      width: 1256px;
      height: 460px;
      margin: 0 auto;
      display: flex;

      img {
        width: 564px;
        height: 460px;
      }
      .text {
        width: 560px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 32px;
        color: #626778;
        margin-left: 132px;
        margin-top: 90px;
        .title {
          width: 310px;
          height: 54px;
          font-size: 36px;
          font-weight: bold;
          line-height: 54px;
          color: #161e2a;
          margin-bottom: 30px;
        }
      }
    }
  }
  .outlook {
    height: 480px;
    background-image: url(../assets/img/yedoneback5.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 148px;
    .outlooktitle {
      width: 310px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 54px;
      color: #ffffff;
      margin: 0 auto;
      text-align: center;
    }
    .outlookinfo {
      width: 800px;
      height: 91px;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 32px;
      color: #ffffff;
      margin: 30px auto 0;
      text-align: center;
    }
  }
}
</style>
